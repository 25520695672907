@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Noto Sans TC', 'Noto Sans SC', 'PingFang', 'Microsoft JhengHei', 'SimHei',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Eina";
  src: local("Eina"),
    url("./fonts/Eina01-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Eina";
  src: local("Eina"),
    url("./fonts/Eina01-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina";
  src: local("Eina"),
    url("./fonts/Eina01-Light.ttf") format("truetype");
  font-weight: lighter;
}

body,
html {
  font-family: 'Eina';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carousel-status{
display: none;
}