.html1 {
  padding: 0.5in;
}
.header1 span,
.td1,
.th1 {
  position: relative;
}
.add,
.cut,
.td1,
.th1 {
  border-width: 0px;
}
.add,
.cut,
.h1 {
  text-align: center;
}
@font-face {
  font-family: "Eina";
  src: local("Eina"),
    url("../fonts/Eina01-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Eina";
  src: local("Eina"),
    url("../fonts/Eina01-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Eina";
  src: local("Eina"),
    url("../fonts/Eina01-Light.ttf") format("truetype");
  font-weight: lighter;
}

.div1 {
  height: 287mm;
  width: 210mm;
  margin-left: auto;
  margin-right: auto;
}

.header1 h1,
.td1,
.th1 {
  border-radius: 0.25em;
}
.h1 {
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-transform: uppercase;
}
.table11 {
  font-size: 75%;
  table-layout: fixed;
  width: 100%;

}
.td1,
.th1 {
  padding: 0.5em;
  text-align: left;
  border-style: solid;
}
.th1 {
  background: #eee;
  border-color: #bbb;
}
.td1 {
  border-color: #ddd;
}
.html1 {
  font: 16px/1 ;
  overflow: auto;
  background: #505050;
  cursor: default;
}
.div1 {
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
  background: #F3F0EA;
}
article,
article address,
.header1,
.table1.inventory,
.table1.meta {
  margin: 0 0 3em;
}
article:after,
.header1:after,
.table1.balance:after,
.table1.meta:after {
  clear: both;
  content: "";
  display: table;
}
.header1 h1 {
  background: #000;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.5em 0;
}
.header1 address {
  float: left;
  font-size: 75%;
  font-style: normal;
  line-height: 1.25;
  margin: 0 1em 1em 0;
}
.header1 address p {
  margin: 0 0 0.25em;
}
.header1 img,
.header1 span {
  display: block;
  float: right;
}
.add,
.cut,
article address {
  float: left;
  font-weight: 700;
}
.header1 span {
  margin: 0 0 1em 1em;
  max-height: 25%;
  max-width: 60%;
}
.cut,
article h1,
.header1 input {
  position: absolute;
}
.header1 img {
  max-height: 75%;
  max-width: 75%;
}
.header1 input {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  top: 0;
  width: 100%;
}
article h1 {
  clip: rect(0 0 0 0);
}
article address {
  font-size: 110%;
}
.table1.balance,
.table1.meta {
  float: right;
  width: 50%;
}
.table1.balance th,
.table1.meta .th1 {
  width: 35%;
}
.table1.balance .td1,
.table1.meta .td1 {
  width: 65%;
}
.table1.inventory {
  clear: both;
  width: 100%;
}
.table1.inventory .th1 {
  font-weight: 700;
  /* text-align: center; */
}
.table1.balance .td1,
.table1.inventory .td1:nth-child(2) {
  text-align: right;
}
.table1.inventory th:first-child {
  width: 80%;
}
.table1.inventory th:nth-child(2) {
  width: 20%;
}
aside h1 {
  border: #999;
  border-width: 0 0 1px;
  margin: 0 0 1em;
  border-bottom-style: solid;
}
.add,
.cut {
  display: block;
  font-size: 0.8rem;
  padding: 0.25em 0.5em;
  width: 0.6em;
  background: #9af;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background-image: -moz-linear-gradient(#00adee 5%, #0078a5 100%);
  background-image: -webkit-linear-gradient(#00adee 5%, #0078a5 100%);
  border-radius: 0.5em;
  border-color: #0076a3;
  color: #fff;
  cursor: pointer;
  text-shadow: 0 -1px 2px rgba(0, 0, 0, 0.333);
}
.add {
  margin: -2.5em 0 0;
}
.add:hover {
  background: #00adee;
}
.cut {
  opacity: 0;
  top: 0;
  left: -1.5em;
  -webkit-transition: opacity 0.1s ease-in;
}
tr:hover .cut {
  opacity: 1;
}
@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  .html1 {
    background: 0 0;
    padding: 0;
  }
  .div1 {
    box-shadow: none;
    margin: 0;
  }
  .add,
  .cut,
  span:empty {
    display: none;
  }
}
@page {
  margin: 0;
}
